module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Творческий колектив «Тимпан». Официальный сайт.","short_name":"Timpam-team.web.app","start_url":"/","background_color":"#E2AA2B","theme_color":"#fff","display":"minimal-ui","icon":"src/assets/fav.png","include_favicon":true},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":560},
    },{
      plugin: require('../node_modules/gatsby-plugin-yandex-metrika/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"64890262","webvisor":false,"trackHash":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
