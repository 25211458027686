// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-music-tsx": () => import("./../src/pages/music.tsx" /* webpackChunkName: "component---src-pages-music-tsx" */),
  "component---src-pages-photos-tsx": () => import("./../src/pages/photos.tsx" /* webpackChunkName: "component---src-pages-photos-tsx" */),
  "component---src-pages-videos-tsx": () => import("./../src/pages/videos.tsx" /* webpackChunkName: "component---src-pages-videos-tsx" */),
  "component---src-templates-album-tsx": () => import("./../src/templates/album.tsx" /* webpackChunkName: "component---src-templates-album-tsx" */),
  "component---src-templates-albums-tsx": () => import("./../src/templates/albums.tsx" /* webpackChunkName: "component---src-templates-albums-tsx" */),
  "component---src-templates-post-tsx": () => import("./../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-posts-tsx": () => import("./../src/templates/posts.tsx" /* webpackChunkName: "component---src-templates-posts-tsx" */)
}

